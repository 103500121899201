/*
 * Antd Button
 */
.kems-ant-btn {
    height: 30px;
    font-size: 0.75rem;
    box-sizing: border-box;
}

/*
 * Antd Avatar
 */
.kems-ant-avatar {
    width: 30px;
    height: 30px;
    cursor: pointer;
    box-sizing: border-box;
}

.kems-ant-avatar-large {
    width: 90px;
    height: 90px;
    cursor: pointer;
    box-sizing: border-box;
}

/*
 * Antd Table
 */
.kems-ant-table {
    width: 100%;
    height: auto;
}

.kems-ant-table-title-container,
.kems-ant-table-secondary-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
}

.kems-ant-table-title-container {
    /*background-color: rgba(0, 54, 114, 1);*/
    background-color: transparent;
    color: whitesmoke;
}

.kems-ant-table-secondary-title-container {
    /*background-color: rgba(0, 54, 114, 0.8);*/
    background-color: transparent;
    color: rgba(245, 245, 245, 0.8);
}

.kems-ant-table-row {
    color: #B1D1FB;
}

.kems-ant-table-editable-row {
    position: absolute;
    top: 100%;
    color: #B1D1FB;
    font-size: 12px;
}

.kems-ant-table-control-cell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
}

.kems-ant-table-control-cell-around {
    justify-content: space-around;
}

.kems-ant-table-control-cell > a {
    display: inline-block;
    width: auto;
    height: 24px;
    margin: 0;
    padding: 0 12px;
    border-radius: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 24px;
    text-align: center;
    color: white;
    overflow: hidden;
}

/*
 * Antd Input
 */
.kems-ant-input {
    width: 260px;
    height: 30px;
    font-size: 0.75rem;
    box-sizing: border-box;
}

.kems-ant-text-area {
    flex-grow: 1;
    width: auto;
    height: 600px;
}

/*
 * Antd Datepicker
 */
.kems-ant-datepicker {
    width: 260px;
    height: 30px;
    font-size: 0.75rem;
    box-sizing: border-box;
}

/*
 * Antd Select
 */
.kems-ant-select {
    width: 260px;
    height: 30px;
    font-size: 0.75rem;
    box-sizing: border-box;
}

/*
 * Antd Cascader
 */
 .kems-ant-cascader {
    width: 260px;
    height: 30px;
    font-size: 0.75rem;
    box-sizing: border-box;
 }

/*
 * Antd Modal
 */
.kems-ant-modal-body,
.kems-ant-modal-form,
.kems-ant-modal-confirm {
    width: 100%;
    height: auto;
}

.kems-ant-modal-scroll-form {
    max-height: 480px;
    min-height: 180px;
    padding-right: 6px;
    overflow-y: auto;
}

.kems-ant-modal-form-item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
}

.kems-ant-modal-form-item > label {
    display: inline-block;
    width: 160px;
    height: auto;
    padding-right: 1rem;
    text-align: right;
    font-size: 0.875rem;
}

.kems-ant-modal-form-item-middle-large > label {
    width: 180px;
}

.kems-ant-modal-form-item-label-large > label {
    width: 220px;
}

.kems-ant-modal-confirm {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 2rem;
}

.kems-ant-modal-confirm > p {
    margin-left: 1rem;
    color: whitesmoke;
    font-size: 0.875rem;
}

/*
 * Ant Drawer
 */
.kems-ant-drawer-data-table,
.kems-ant-drawer-data-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.kems-ant-drawer-data-table-control-menu {
    flex: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
}

.kems-ant-drawer-data-table-content {
    flex-grow: 1;
    width: 100%;
    height: auto;
    overflow-y: auto;
}

.kems-ant-drawer-data-list {
    margin: 0;
    padding: 0;
    overflow-y: auto;
    box-sizing: border-box;
}

/*
 * Ant Change Common Class
 */
.kems-ant-fill-width {
    width: 100%;
}

.kems-ant-flex-grow-1 {
    flex-grow: 1;
    width: auto;
}

/*
 * Ant Icon
 */
.kems-ant-icon-primary {
    color: #0083F2;
}
