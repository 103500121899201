@font-face {
  font-family: "iconfont"; /* Project id 2066214 */
  src: url('iconfont.woff2?t=1630377249675') format('woff2'),
       url('iconfont.woff?t=1630377249675') format('woff'),
       url('iconfont.ttf?t=1630377249675') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-parameter:before {
  content: "\e61b";
}

.icon-concept-battery:before {
  content: "\e646";
}

.icon-concept-PV:before {
  content: "\e648";
}

.icon-concept-grid:before {
  content: "\e649";
}

.icon-concept-PCS:before {
  content: "\e64a";
}

.icon-concept-KDC:before {
  content: "\e64b";
}

.icon-mobile-phone:before {
  content: "\e61e";
}

.icon-email:before {
  content: "\e600";
}

.icon-camera:before {
  content: "\e628";
}

.icon-edit:before {
  content: "\e631";
}

.icon-delete-account:before {
  content: "\e605";
}

.icon-allocate:before {
  content: "\e64f";
}

.icon-reset-password:before {
  content: "\e728";
}

.icon-today-charge:before {
  content: "\e608";
}

.icon-battery:before {
  content: "\e69d";
}

.icon-today-discharge:before {
  content: "\e729";
}

.icon-exit:before {
  content: "\e651";
}

.icon-setup:before {
  content: "\e607";
}

.icon-statistics:before {
  content: "\e613";
}

.icon-total-current-of-branch:before {
  content: "\e665";
}

.icon-total-PV-voltage:before {
  content: "\e666";
}

.icon-PV-input-total-power:before {
  content: "\e61d";
}

.icon-temperature:before {
  content: "\e604";
}

.icon-fire-protection:before {
  content: "\e66d";
}

.icon-door:before {
  content: "\e609";
}

.icon-icon-water-immersion:before {
  content: "\e60a";
}

.icon-fan:before {
  content: "\e6cc";
}

.icon-PCS-warehouse-ambient-temperature:before {
  content: "\e640";
}

.icon-insulation-test:before {
  content: "\e641";
}

.icon-battery-warehouse-relative-humidity:before {
  content: "\e643";
}

.icon-battery-warehouse-ambient-temperature:before {
  content: "\e644";
}

.icon-PCS-warehouse-relative-humidity:before {
  content: "\e645";
}

.icon-angle_up:before {
  content: "\e670";
}

.icon-angle_down:before {
  content: "\e671";
}

.icon-showpassword:before {
  content: "\ea3f";
}

.icon-password:before {
  content: "\e620";
}

.icon-hide:before {
  content: "\e6fa";
}

.icon-language:before {
  content: "\e60c";
}

.icon-home:before {
  content: "\e6f3";
}

.icon-shanchu:before {
  content: "\e614";
}

.icon-bianji:before {
  content: "\e8ac";
}

.icon-yunshangchuan:before {
  content: "\e6d0";
}

.icon-alarm:before {
  content: "\e650";
}

