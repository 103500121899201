/* App.css is the CSS file used to save common style. */
/*
 * Clear the default style of element
 */
html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 100%;
    font-family: "PingFang SC", Arial, "微软雅黑", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-family: "PingFang SC", "Arial Black", "微软雅黑", sans-serif;
}

p {
    margin: 0;
    padding: 0;
}

/*
 * common style
 */
#root,
.App {
    width: 100%;
    height: 100%;
}

/*
 * Tool style
 */
.kems-sticky {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    left: 0;
}

.kems-relative {
    position: relative;
    top: 0;
    left: 0;
}

.kems-absolute {
    position: absolute;
    top: 0;
    left: 0;
}

.kems-d-none {
    display: none;
}

.kems-d-flex-row-start-center {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.kems-d-flex-row-center-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.kems-d-flex-column-start-center {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.kems-d-flex-column-center-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.kems-visibility-hidden {
    visibility: hidden;
}

.kems-all-fill {
    width: 100%;
    height: 100%;
}

.kems-width-fill {
    width: 100%;
    height: auto;
}

.kems-only-width-fill {
    width: 100%!important;
}

.kems-margin-row-1rem {
    margin: 0 1rem;
}

.kems-margin-right-1rem {
    margin-right: 1rem;
}

.kems-margin-right-1rem-half {
    margin-right: 0.5rem;
}

.kems-margin-top-1rem {
    margin-top: 1rem;
}

.kems-margin-left-1rem {
    margin-left: 1rem;
}

.kems-margin-bottom-1rem {
    margin-bottom: 1rem;
}

.kems-margin-bottom-2rem {
    margin-bottom: 2rem;
}

.kems-primary {
    background-color: #0083F2;
    color: white;
}

.kems-primary:hover {
    background-color: rgba(0, 131, 242, 0.66);
}

.kems-default {
    background-color: #F8F8F8;
    color: #666666;
}

.kems-default:hover {
    background-color: rgba(248, 248, 248, 0.66);
}

.kems-bg-warning {
    background-color: #FAAD14;
}

.kems-bg-warning:hover {
    background-color: rgba(250, 173, 20, 0.66);
}

.kems-bg-error {
    background-color: #F5222D;
}

.kems-bg-error:hover {
    background-color: rgba(245, 34, 45, 0.66);
}

.kems-color-warning {
    color: #FAAD14;
}

.kems-color-error {
    color: #F5222D;
}

/*
 * custom button
 */
button.primary {
    background-color: #0083F2;
    color: white;
}

button.primary:active {
    background-color: rgba(0, 131, 242, 0.6)
}

button.secondary {
    background-color: white;
    color: #666666;
}

button.secondary:active {
    background-color: rgba(255, 255, 255, 0.6);
}

.kem-button-icon {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-right: 10px;
}


/*
 * custom scroll bar
 */
.kems-custom-scroll-bar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.kems-custom-scroll-bar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px #D8D8D8;
    background: #535353;
}

.kems-custom-scroll-bar::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 0 0 5px #D8D8D8;
    background-color: #EDEDED;
}

/*
 * The identification of required item.
 * Invalid for HTML5 single label.
 */
.required:before,
.kems-label-required:before {
    content: '*';
    margin-right: 0.3rem;
    color: #FF0000;
    font-size: 0.75rem;
}

/*
 * This is the style loaded when the page jumps.
 */
.kems-loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.kems-loading-icon {
    color: #177ddc;
    font-size: 2rem
}

/*
 * No Data
 */
.kems-no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.kems-no-data > p {
    color: whitesmoke;
    font-size: 0.8125rem;
    font-weight: bold;
}



/* tips */
.kems-tips {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    text-align: center;
    background-color: #CF1322;
    color: white;
    font-size: 12px;
    font-weight: bold;
    box-sizing: border-box;
}

.kems-tips:hover {
    background-color: #F5222D;
}



/* loading */
.ant-spin-spinning {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.35);
    z-index: 9999;
}

.ant-spin.ant-spin-show-text .ant-spin-text {
    margin-top: 8px;
    font-size: 15px;
}
